import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('div',{staticClass:"pa-2"},[_c('h1',{staticClass:"primary--text"},[_vm._v("Statut")]),(_vm.statut_fetch_status === 'LOADED')?_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Nom du statut ")])])]),_c('tbody',[_vm._l((_vm.statuts),function(statut){return _c('tr',{key:statut.id},[_c('td',[_vm._v(_vm._s(statut.name))]),_c('td',[_c(VBtn,{staticClass:"white--text",attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteStatut(statut)}}},[_c(VIcon,[_vm._v("mdi-trash-can")]),_vm._v("Supprimer")],1),_c('EditStatut',{attrs:{"statut":statut},on:{"reload":function($event){return _vm.$store.dispatch('dossier/statuts/getStatuts')}}})],1)])}),_c('tr',[_c('td',[_c(VTextField,{attrs:{"rules":[function (value) { return value !== '' && /^\d+$/.test(value[0]) || 'Ne doit pas être vide et doit commencer par un nombre.'; }]},model:{value:(_vm.statutModel.name),callback:function ($$v) {_vm.$set(_vm.statutModel, "name", $$v)},expression:"statutModel.name"}})],1),_c('td',[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.createStatut}},[_vm._v("Créer")])],1)])],2)]},proxy:true}],null,false,1292093326)}):_c(VRow,[_c(VProgressCircular,{attrs:{"color":"primary","indeterminate":""}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }